import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image"
export default class extends Controller {
  static values = {
    src: String
  }

  static targets = ['image']

  static observer = new IntersectionObserver(this.lazyLoad.bind(this), {
    rootMargin: '100% 0%',
    threshold: 0.5,
  })

  static lazyLoad(elements) {
    elements.forEach(element => {
      if (element.intersectionRatio > 0) {
        const src = element.target.dataset.src
                      .replace(/w_auto/, `w_${element.target.clientWidth}`)
                      .replace(/dpr_auto/, `dpr_${window.devicePixelRatio}`)
        element.target.src = src
        this.observer.unobserve(element.target)
      }
    })
  }

  connect() {
    this.constructor.observer.observe(this.imageTarget)
    this.imageTarget.addEventListener('load', this.unveil)
  }

  disconnect() {
    this.constructor.observer.unobserve(this.imageTarget)
  }

  unveil(e) {
    this.style.opacity = 1
  }
}
