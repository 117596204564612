import { Controller } from '@hotwired/stimulus';
import * as focusTrap from 'focus-trap';

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    clickOutsideHides: { type: Boolean, default: true },
    escapeHides: { type: Boolean, default: true },
    visible: { type: Boolean, default: false },
  };

  static targets = ['element'];

  initialize() {
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  connect() {
    this.focusTrap = focusTrap.createFocusTrap(this.elementTarget, {
      onActivate: this.show,
      onDeactivate: this.hide,
      escapeDeactivates: this.clickOutsideHidesValue,
      clickOutsideDeactivates: this.escapeHidesValue,
      checkCanFocusTrap: this.constructor.checkCanFocusTrap,
    });
  }

  disconnect() {
    this.focusTrap = null;
  }

  show() {
    this.visibleValue = true;
  }

  hide() {
    this.visibleValue = false;
  }

  toggle() {
    this.visibleValue = !this.visibleValue;
  }

  visibleValueChanged(value, oldValue) {
    if (oldValue === undefined) return;

    if (value) {
      this.elementTarget.classList.add('js-is-visible');
      this.focusTrap.activate();
      this.dispatch('show');
    } else {
      this.elementTarget.classList.remove('js-is-visible');
      this.focusTrap.deactivate();
      this.dispatch('hide');
    }
  }

  static checkCanFocusTrap(containers) {
    return Promise.all(
      containers.map((container) => new Promise((resolve) => {
        container.addEventListener('transitionend', resolve);
      })),
    );
  }
}
