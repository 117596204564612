import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['carousel', 'prevButton', 'nextButton'];

  initialize() {
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.carouselWidth = null;
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect) {
          this.carouselWidth = entry.contentRect.width;
        }
      }
    });
  }

  connect() {
    this.carouselWidth = this.carouselTarget.clientWidth;
    this.resizeObserver.observe(this.carouselTarget);
  }

  prevPage() {
    this.carouselTarget.scrollBy({ left: -this.carouselWidth, top: 0, behavior: 'smooth' });
    console.log('scroll position', this.carouselTarget.scrollLeft, 'width', this.carouselTarget.scrollWidth, 'carouselWidth', this.carouselWidth);
  }

  nextPage() {
    this.carouselTarget.scrollBy({ left: this.carouselWidth, top: 0, behavior: 'smooth' });
    console.log('scroll position', this.carouselTarget.scrollLeft, 'width', this.carouselTarget.scrollWidth, 'carouselWidth', this.carouselWidth);
  }
}
