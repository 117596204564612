import AdController from "./ad_controller";
import { targetingValues } from './ad_controller';

// Connects to data-controller="display-ad"
export default class extends AdController {
  static prefix = 'display_ad'
  static values = {
    ...targetingValues,
    dimensionsMobile: {
      type: Array,
      default: []
    },
    dimensionsTablet: {
      type: Array,
      default: []
    },
    dimensionsDesktop: {
      type: Array,
      default: []
    }
  }

  get sizeMapping() {
    const mapping = googletag.sizeMapping()
    mapping
      .addSize([0, 0], this.dimensionsMobileValue)
      .addSize([768, 0], this.dimensionsTabletValue)
      .addSize([960, 0], this.dimensionsDesktopValue)
    return mapping.build()
  }
}
