import { Controller } from '@hotwired/stimulus';
import { networkCode, property, fetchTargeting } from '@/utils/google_publisher_tag';

window.googletag = window.googletag || { cmd: [] };

const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      entry.target.dataset.inViewport = entry.isIntersecting;
    });
  },
  {
    root: null, // Default to the viewport
    rootMargin: '0px',
    threshold: 0.1,
  },
);

// Refresh ads slots every 15 secs
setInterval(() => {
  googletag.cmd.push(() => {
    const slots = googletag.pubads().getSlots();

    slots.forEach((slot) => {
      const slotElement = document.getElementById(slot.getSlotElementId());

      if (slotElement && slotElement.dataset.inViewport === 'true') {
        googletag.pubads().refresh([slot]);
      }
    });
  });
}, 15 * 1000);

googletag.cmd.push(() => {
  googletag.pubads().enableSingleRequest();
  googletag.pubads().collapseEmptyDivs();
  googletag.pubads().enableLazyLoad({
    fetchMarginPercent: 500,
    renderMarginPercent: 100,
    mobileScaling: 2.0,
  });

  googletag.pubads().addEventListener('slotRequested', (event) => {
    const slotElementId = event.slot.getSlotElementId();
    window.dataLayer.push({
      event: 'gpt:slotRequested',
      gpt: {
        adSlotId: slotElementId,
      },
    });
    const e = new Event('gpt:slotRequested');
    try {
      document.getElementById(slotElementId).dispatchEvent(e);
    } catch (e) {
      console.error(`Unable to find element with id: ${slotElementId}`);
    }
  });

  googletag.pubads().addEventListener('slotRenderEnded', (event) => {
    const slotElementId = event.slot.getSlotElementId();

    // Start observing the slot
    observer.observe(document.getElementById(slotElementId));

    window.dataLayer.push({
      event: 'gpt:slotRenderEnded',
      gpt: {
        adSlotId: slotElementId,
        adSize: (event.size && typeof event.size === 'object') ? event.size.join('x') : event.size,
        isEmpty: event.isEmpty,
      },
    });
  });

  googletag.pubads().addEventListener('slotOnload', (event) => {
    const slotElementId = event.slot.getSlotElementId();
    window.dataLayer.push({
      event: 'gpt:slotOnload',
      gpt: {
        adSlotId: slotElementId,
      },
    });
    const e = new Event('gpt:slotOnload');
    try {
      const element = document.getElementById(slotElementId);
      element.dispatchEvent(e);
    } catch (e) {
      console.error(`Unable to find element with id: ${slotElementId}`);
    }
  });

  googletag.enableServices();
});

// document.addEventListener('DOMContentLoaded', () => {
const metadata = fetchTargeting();
googletag.cmd.push(() => {
  googletag.pubads().clearTargeting();

  for (const key in metadata) {
    if (metadata.hasOwnProperty(key)) {
      googletag.pubads().setTargeting(key, metadata[key]);
    }
  }
});
// });

const targetingValues = {
  targetingVertical: {
    type: String,
    default: metadata.vertical || '',
  },
  targetingChannel: {
    type: String,
    default: '',
  },
  targetingTags: {
    type: String,
    default: '',
  },
  targetingUuid: {
    type: String,
    default: '',
  },
  targetingMicrosites: {
    type: String,
    default: '',
  },
};

export { targetingValues };

export default class extends Controller {
  static adCounter = 1;

  initialize() {
    this.createSlot = this.createSlot.bind(this);
    this.destroySlot = this.destroySlot.bind(this);
    this.slotOnload = this.slotOnload.bind(this);
    this.slotRequested = this.slotRequested.bind(this);
    this.slotId = `${this.constructor.prefix}_${this.constructor.adCounter++}`;
    this.slot = null;
  }

  connect() {
    this.element.id = this.slotId;
    this.element.classList.add('loading');
    googletag.cmd.push(this.createSlot);
    this.element.addEventListener('gpt:slotOnload', this.slotOnload);
    this.element.addEventListener('gpt:slotRequested', this.slotRequested);
  }

  disconnect() {
    googletag.cmd.push(this.destroySlot);
    this.element.removeEventListener('gpt:slotOnload', this.slotOnload);
    this.element.removeEventListener('gpt:slotRequested', this.slotRequested);
  }

  createSlot() {
    this.slot = googletag.defineSlot(this.adSlotPath, [0, 0], this.slotId);
    this.slot.addService(googletag.pubads());
    this.slot.defineSizeMapping(this.sizeMapping);

    if (this.hasTargetingVerticalValue) {
      this.slot.setTargeting('vertical', this.targetingVerticalValue);
    }
    if (this.hasTargetingChannelValue) {
      this.slot.setTargeting('channel', this.targetingChannelValue);
    }
    if (this.hasTargetingTagsValue) {
      this.slot.setTargeting('tags', this.targetingTagsValue);
    }
    if (this.hasTargetingUuidValue) {
      this.slot.setTargeting('uuid', this.targetingUuidValue);
    }
    if (this.hasTargetingMicrositesValue) {
      this.slot.setTargeting('microsites', this.targetingMicrositesValue);
    }

    googletag.display(this.slotId);
  }

  destroySlot() {
    googletag.pubads().clear([this.slot]);
    this.slot = null;
  }

  slotOnload(event) {
    // console.log('slotOnload', this.element, event)
    this.dispatch('slotOnload');

    this.element.classList.remove('loading');
    this.element.classList.add('loaded');
  }

  slotRequested(event) {
    // console.log('slotRequested', this.element, event)
    this.dispatch('slotRequested');
  }

  get adSlotPath() {
    const pathComponents = ['', networkCode, property];
    if (this.targetingVerticalValue) {
      pathComponents.push(this.targetingVerticalValue);
    }
    return pathComponents.join('/');
  }
}
