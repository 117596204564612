import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { isOpen: { type: Boolean, default: false } };

  static targets = ['toggleable'];

  open() {
    this.isOpenValue = true;
  }

  close() {
    this.isOpenValue = false;
  }

  toggle() {
    this.isOpenValue = !this.isOpenValue;
  }

  isOpenValueChanged(value, oldValue) {
    if (oldValue === undefined) return;

    if (value) {
      this.toggleableTarget.classList.add('js-is-visible');
      this.dispatch('show');
    } else {
      this.toggleableTarget.classList.remove('js-is-visible');
      this.dispatch('hide');
    }
  }
}
