import { Controller } from '@hotwired/stimulus'


export default class extends Controller {
  static targets = ['menuToggle', 'slideover', 'search', 'searchInput', 'scrim']

  initialize() {
    this.menuOpen = false
  }

  /**
   * Unfreeze scroll on Turbo navigation changes
   */
  disconnect() {
    this.unfreezeScroll();
  }

  openMenu() {
    this.menuOpen = true
    this.freezeScroll()
    this.slideoverTarget.classList.add('is-open')
  }

  closeMenu() {
    this.menuOpen = false
    this.unfreezeScroll()
    this.slideoverTarget.classList.remove('is-open')
  }

  openSearch() {
    this.searchOpen = true
    // this.freezeScroll()
    this.searchTarget.classList.add('is-open')
    setTimeout(() => {
      this.searchInputTarget.focus()
    }, 10)
  }

  closeSearch() {
    this.searchOpen = false
    // this.unfreezeScroll()
    this.searchTarget.classList.remove('is-open')
    this.searchInputTarget.value = null
  }

  handleEscape(event) {
    if (event.keyCode == 27) {
      this.closeSearch()
      this.closeMenu()
    }
  }

  reset() {
    this.closeMenu()
  }

  freezeScroll() {
    document.documentElement.classList.add('js-freeze-scroll');
  }

  unfreezeScroll() {
    document.documentElement.classList.remove('js-freeze-scroll');
  }
}
