import { Controller } from '@hotwired/stimulus';

/**
 * Connects to data-controller="toggle-group". Wraps a group of toggleable elements
 * (`data-controller="toggle"`) to ensure only one is visible at a time.
 *
 * Example:
 *
 *  <div data-controller="toggle-group">
 *    <div data-controller="toggle">
 *      <button data-action="toggle#toggle">Show/Hide</button>
 *      <div data-toggle-target="toggleable">Toggleable Content 1</div>
 *    </div>
 *    <div data-controller="toggle">
 *      <button data-action="toggle#toggle">Show/Hide</button>
 *      <div data-toggle-target="toggleable">Toggleable Content 2</div>
 *    </div>
 *  </div>
 */
export default class extends Controller {
  initialize() {
    this.onToggleShow = this.onToggleShow.bind(this);
  }

  connect() {
    this.element.addEventListener('toggle:show', this.onToggleShow);
  }

  disconnect() {
    this.element.removeEventListener('toggle:show', this.onToggleShow);
  }

  onToggleShow(event) {
    this.toggleables.forEach((toggleable) => {
      if (event.target === toggleable) return;

      const el = toggleable;
      el.dataset.toggleIsOpenValue = false;
    });
  }

  get toggleables() {
    return this.element.querySelectorAll('[data-toggle-is-open-value="true"]');
  }
}
