import dot from 'dot';
import AdController, { targetingValues } from './ad_controller';

const nativeAdTemplate = dot.template(
  `
  <div class="item__thumb">
    <div class="thumbnail" data-controller="image">
      <a href="{{=url}}">
        <img data-image-target="image" data-src="{{=thumbnail}}"/>
      </a>
    </div>
  </div>
  <div class="item__content">
    <p class="item__kicker">Sponsored</p>
    <h2 class="item__title">
      <a href="{{=url}}">{{=title}}</a>
    </h2>
    <p class="item__teaser">{{=teaser}}</p>
    <p class="item__meta">Presented by {{=advertiser}}</p>
  </div>
  `,
  { argName: ['title', 'thumbnail', 'teaser', 'url', 'advertiser'] },
);

// Connects to data-controller="native-ad"
export default class extends AdController {
  static prefix = 'native_ad';

  static values = { renderAdjacent: { type: Boolean, default: false }, ...targetingValues };

  initialize() {
    super.initialize();
    this.loadNativeAd = this.loadNativeAd.bind(this);
  }

  connect() {
    super.connect();
    this.element.addEventListener('native-ad-load', this.loadNativeAd);
  }

  disconnect() {
    super.disconnect();
    this.element.removeEventListener('native-ad-load', this.loadNativeAd);
  }

  loadNativeAd(event) {
    // console.log('native ad load', this.slotId)
    if (this.renderAdjacentValue) {
      this.element.nextElementSibling.innerHTML = nativeAdTemplate(event.detail);
      this.element.nextElementSibling.classList.add('is-cream');
    } else {
      this.element.insertAdjacentHTML('afterbegin', nativeAdTemplate(event.detail));
      this.element.classList.add('is-cream');
    }
  }

  get sizeMapping() {
    return googletag.sizeMapping().addSize([0, 0], [[1, 1]]).build();
  }
}
