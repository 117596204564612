import { Controller } from '@hotwired/stimulus';
import stickybits from 'stickybits';
import { smallDesktop } from '../utils/breakpoints';

// Connects to data-controller="sticky"
export default class extends Controller {
  initialize() {
    this.instance = null;
    this.update = () => this.instance.update();
  }

  connect() {
    this.instance = stickybits(this.element, { useStickyClasses: true });
    smallDesktop.addEventListener('change', this.update);
  }

  disconnect() {
    smallDesktop.removeEventListener('change', this.update);
    this.instance.cleanup();
  }
}
