import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['slideImage', 'slideContent', 'currentCount', 'totalCount']
  static values = {
    index: Number,
    count: Number
  }

  next() {
    if (this.indexValue == this.countValue) {
      this.indexValue = 1
    } else {
      this.indexValue++
    }
  }

  previous() {
    if (this.indexValue == 1) {
      this.indexValue = this.countValue
    } else {
      this.indexValue--
    }
  }

  indexValueChanged() {
    this.showCurrentSlide()
    this.currentCountTarget.innerHTML = this.indexValue
  }

  countValueChanged() {
    this.totalCountTarget.innerHTML = this.countValue
  }

  showCurrentSlide() {
    this.slideImageTargets.forEach((element, index) => {
      element.hidden = (index + 1) != this.indexValue
    })
    this.slideContentTargets.forEach((element, index) => {
      element.hidden = (index + 1) != this.indexValue
    })
  }
}
