const networkCode = document.querySelector('meta[name="gpt:networkCode"]').content
const property = document.querySelector('meta[name="gpt:property"]').content

const fetchTargeting = () => {
  const elements = document.querySelectorAll('meta[name*="gpt:"]')
  const mapped = Array.from(elements).map(el => {
    return [el.name.match(/gpt:(.+)/)[1], el.content]
  })
  const { networkCode, property, ...rest } = Object.fromEntries(mapped)
  return rest
}

export { networkCode, property, fetchTargeting }
