import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="scroll"
export default class extends Controller {
  connect() {
    this.isLocked = false;
  }

  /**
   * Lock scrolling.
   * @returns null
   */
  lock() {
    if (this.isLocked) return;

    this.prevScrollY = window.scrollY;
    Object.assign(document.documentElement.style, {
      width: '100vw',
      top: `-${this.prevScrollY}px`,
      position: 'fixed',
      overflowY: 'hidden',
    });

    this.isLocked = true;
  }

  /**
   * Unlock scrolling.
   * @returns null
   */
  unlock() {
    if (!this.isLocked) return;

    Object.assign(document.documentElement.style, {
      width: null,
      top: null,
      position: null,
      overflowY: null,
    });

    window.scrollTo(0, this.prevScrollY);
    this.isLocked = false;
  }
}
