import { Controller } from "@hotwired/stimulus"
import "sharer.js";

// Connects to data-controller="share-button"
export default class extends Controller {
  connect() {
    this.element.dataset.url = this.getCanonicalURL()
    this.element.dataset.subject = this.getTitle()
    this.element.title = this.element.querySelector('span.visually-hidden').innerHTML
    window.Sharer.init()
  }

  getCanonicalURL() {
    const metatag = document.querySelector('link[rel="canonical"]')
    if (metatag) {
      return metatag.href
    } else {
      return window.location
    }
  }

  getTitle() {
    const metatag = document.querySelector('meta[name="og:title"]')
    if (metatag) {
      return metatag.content
    }
  }
}
